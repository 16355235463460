import React, { Component } from 'react';
import _ from 'underscore';

export default {
	tungumal: {
		'DA': {
			code: 'dk',
			name: 'Dansk'
		},
		'NO-B': {
			code: 'no',
			name: 'Norsk bokmål'
		},
		'NO-N': {
			code: 'nn',
			name: 'Nynorsk'
		},
		'SE': {
			code: 'se',
			name: 'Svenska'
		},
		'FO': {
			code: 'fo',
			name: 'Føroysk',
			langMenuName: 'Føroyskt'
		},
		'FI': {
			code: 'fi',
			name: 'Suomi'
		}
	},

	langCodes: [
		'is', 'dk', 'no', 'nn', 'se', 'fo', 'fi'
	],

	specialChars: [
		'á', 'ð', 'é', 'í', 'ó', 'ú', 'ý', 'þ', 'æ', 'ö', 'ä', 'å', 'ø'
	],

	getIslexLangCode: function(lang) {
		for (let key in this.tungumal) {
			if (this.tungumal[key].code == lang) {
				return key;
			}
		}

		return null;
	},

	formatOfl: function(ofl) {
		let currentLang = window.currentLang.toLowerCase() || 'is';

		if (window.islexData && window.islexData.oflTrans) {
			let oflObj = _.findWhere(window.islexData.oflTrans, {
				mal: currentLang,
				ofl: ofl
			});

			if (oflObj) {
				return <span className="ofl-ofl" title={oflObj.trofl2}>{oflObj.trofl}</span>;
			}
			else {
				return <span className="ofl-ofl">{ofl}</span>;
			}
		}
		else {
			return <span className="ofl-ofl">{ofl}</span>;
		}
	},

	firstLetterUpperCase: function(str) {
		return str.charAt(0).toUpperCase()+str.slice(1);
	}
}