export default {
	siteTitle: 'ISLEX',
	site: 'islex',
	pageSize: 50,

	apiRoot: process.env.REACT_APP_API_ROOT,
	audioUrl: '//islex.arnastofnun.is/islex-files/audio/',
	imageUrl: '//islex.is/myndir/',
	//imageUrl: '//islex.arnastofnun.is/islex-files/myndir/',

	gaTrackingId: 'G-E397QXDJ2G'
}
